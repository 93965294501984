<template>
  <div class="relative caret-transparent max-w-3500px">    
    <!--HEADER -->
    <div style="height:3em;top:0;left:0;z-index:999;position:absolute;width:100%;" class = "grid grid-cols-header bg-opacity-70 bg-black">                 
      <!--NAVIGATION -->
      <div class="grid content-center justify-items-center xsm:justify-items-end sm::justify-items-end">        
        <span v-if="!isOpen" @click="isOpen=true" class="icon-bars cursor-pointer mr-6 "></span>
        <Slide noOverlay :burgerIcon="false" :closeOnNavigation="true" :isOpen="isOpen" @closeMenu="isOpen = false">
          <div v-for="(nav) in getNavTopLevel()" :key="nav.key" >
            <router-link :to="nav.link">{{getLang()==="de"?nav.titel.de:nav.titel.en}}</router-link>
          </div>
          <span @click="goToKontakt" class="cursor-pointer"><a>{{getLang()==="de"?"KONTAKT":"CONTACT"}}</a></span>
        </Slide>
      </div>
      <!--LOGO-->
      <div style="height:3em;width:100%;" class="grid justify-items-center content-center">
        <img class="logoImg" :src="rootUrl+'/img/Logo-Manmade-Creme.gif'">
      </div>
      <!--LANGUAGE-->
      <div style="font-size:0.8em;" class = "grid grid-cols-footer-2sp justify-start py-3 px-3 text-left ">
        <span :title="getLang()==='de'?'Sprache: deutsch':'Language: german'" @click="setLangClick('de')" class="mr-1 cursor-pointer">
          <a>DE</a></span>
        <span :title="getLang()==='de'?'Sprache: englisch':'Language: english'" @click="setLangClick('en')" class="mr-1 cursor-pointer">
          <a>EN</a></span>
      </div>
    </div>
    <!--CONTENT -->
    <div style="top:0;left:0;position: absolute;width:100%;z-index:998" >      
      <!--SLIDER -->
      <div class="slider min-h-32">
        <vueper-slides ref="sl" :autoplay="slAutoplay" :pauseOnTouch="true" :pauseOnHover="true" :lazy="true" :touchable="false" :bullets="false" :fixedHeight="true" 
        >
          <vueper-slide v-for="(slide, i) in slides" :link="slide.link" :content="slide.content" :key="i" />            
        </vueper-slides>
      </div>
      <!--LOGO -->
      <div ref="logo" class="grid w-full place-items-center logo">
        <img class="logoimg" :src="rootUrl+'/img/Logo-Schwarz.gif'">
      </div>      
      <!--PAGES  -->
      <router-view  />
      <!--FOOTER -->
      <footerSrc ref="footer" /> 
      <!-- GO TOP-->
      <div ref="scrTop" style="display:none;height:52px;width:52px;bottom:0px;left:0px;z-index:1010;position:sticky;" class="content-center mx-2 mt-1">
        <i @click="goToTop" class="icon-chevron-circle-up cursor-pointer" :title="getLang()==='de'?'nach oben':'go to top'"></i>
      </div>
    </div>         
  </div>
</template>
<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import { Slide } from "vue3-burger-menu";
import footerSrc from "@/components/Footer.vue";
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';
import { mapState } from 'vuex';
import { ref } from 'vue';
export default {
  name: "app", 
  setup() {
    const sl = ref(null);
    const scrTop = ref(null);
    const footer = ref(null);
    const logo = ref(null);
    return {sl,scrTop,footer,logo}
  },
  components: {
    VueperSlides, 
    VueperSlide,
    Slide,
    footerSrc,
  }, 
  computed: { 
    ...mapGetters([ 'getLang','getSliderImg','getNavTopLevel','getAktiveSl']),
    ...mapState(['aktiveSl','rootUrl','autoplay']), 
  },
  methods:{        
    ...mapMutations(['setLang','setSlCont','setAktiveSl','setEl','setLogo','setAutostart']),
    goToTop(){ window.scrollTo(0, 0) },    
    goToKontakt(){ this.footer.$el.scrollIntoView() },
    setLangClick (ev) { this.setLang(ev) },
    getSlices(){ this.slides = (window.innerWidth < 1024 && this.orientation === "portrait-primary")?this.getSliderImg("xs"):this.slides =this.getSliderImg("xl") },
    initSlider(){        
      try {        
        if(this.sl){
          var img = document.getElementById('slider1');
          if(img){ 
            let h = img.offsetHeight;
            this.sl.$el.style.height =`${h}px`;            
          }
        }
      } catch (error) { console.log("initSlider error: ", error) }         
    },
    setOrientation(){       
      this.orientation = window.innerWidth > window.innerHeight ? "landscape-primary" : "portrait-primary";      
      this.windowWidth=window.innerWidth;   
    },    
    onResize(){      
      this.setOrientation();  
      this.getSlices();
      setTimeout(() => { this.initSlider(); }, 300 );                                                   
    },    
    readystatechange:function(event){
      if (event.target.readyState === 'complete') { this.onResize() }
    },
    onScroll:function(){
      let y;
      if (window.pageYOffset != undefined) { y = window.pageYOffset } else { let doc = document,ele = doc.documentElement,b = doc.body; y = ele.scrollTop || b.scrollTop || 0; }
      if(window.scrollbars.visible){ if(y > 150){ this.scrTop.style.display= "grid" } else { this.scrTop.style.display= "none" } }     
    }
  }, 
  beforeMount(){ 
    this.setSlCont();
    this.onResize();    
  },
  unmounted(){ 
    window.removeEventListener('resize',this.onResize)
    document.removeEventListener('readystatechange', this.readystatechange)
    document.removeEventListener('scroll', this.onScroll)    
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    document.addEventListener('readystatechange', this.readystatechange);
    document.addEventListener('scroll', this.onScroll);
    this.setEl(this.sl);
    this.setLogo(this.logo); 
    this.setAutostart(true);        
    this.onResize();
    this.slAutoplay =true;
  },
  updated(){ this.onResize() },
  watch: { windowWidth() { this.onResize()}, autoplay(n){ this.slAutoplay = n } 
  },
  data: () => ({ isOpen: false, orientation:'', slides:[], slAutoplay:false })
}
</script>
