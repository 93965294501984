<template>
  <div></div>
</template>
<script>
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';
export default {
  name: 'HomeSrc',
  computed:{ ...mapGetters([ 'getEl' ]), },
  methods:{ ...mapMutations(['setAutostart']),},
  mounted(){    
    let el=this.getEl(); 
    el.goToSlide(0);
    this.setAutostart(true);  
    el.resumeAutoplay(); 
  }
}
</script>
