import { createRouter, createWebHashHistory } from 'vue-router'
import HomeSrc from '../views/Home.vue'
const routes = [{
        path: '/',
        name: 'Home',
        component: HomeSrc
    },
    {
        path: '/Werkstatt',
        name: 'Werkstatt',
        component: () =>
            import ('../views/Werkstatt.vue')
    },
    {
        path: '/Interior',
        name: 'Interior',
        component: () =>
            import ('../views/Interior.vue')
    },
    {
        path: '/Sattler',
        name: 'Sattler',
        component: () =>
            import ('../views/Sattler.vue')
    },
    {
        path: '/Oldtimer',
        name: 'Oldtimer',
        component: () =>
            import ('../views/Oldtimer.vue')
    },
    {
        path: '/Sonderbau',
        name: 'Sonderbau',
        component: () =>
            import ('../views/Sonderbau.vue')
    },
    {
        path: '/Design',
        name: 'Design',
        component: () =>
            import ('../views/Design.vue')
    },
    {
        path: '/Impressum',
        name: 'Impressum',
        component: () =>
            import ('../views/Impressum.vue')
    },
    {
        path: '/Datenschutz',
        name: 'Datenschutz',
        component: () =>
            import ('../views/Datenschutz.vue')
    },
]
const router = createRouter({
    history: createWebHashHistory(),
    routes
})
export default router