import { createStore } from 'vuex';
import mwb from '@/json/mwb.json';
import werkstatt from '@/json/werkstatt.json';
import interior from '@/json/interior.json';
import sattler from '@/json/sattler.json';
import oldtimer from '@/json/oldtimer.json';
import sonderbau from '@/json/sonderbau.json';
import design from '@/json/design.json';

/* 
ACHTUNG!! 
befor serve set isLocsal = true
befor build set isLocsal = false
*/
const isLocal = false;

export default createStore({
    state: {
        rootUrl: isLocal ? "https://test.meisterwerk-bremen.de" : window.location.origin,
        //lang: (navigator.language || navigator.userLanguage) === "de-DE" ? "de" : "en",
        lang: "de",
        aktiveSl: 0,
        autoplay: true,
        el: Object,
        logo: Object,
        lstNav: [],
        lstCont: [
            { id: "d10cb6e2-dc3a-4904-869a-5a16b81872bb", cont: werkstatt },
            { id: "2b7916b3-527d-44ec-b472-26e29cfc28d7", cont: interior },
            { id: "2ab47114-182c-4476-89db-bb52a14a0ad8", cont: sattler },
            { id: "d26a91ca-ce2c-49a6-8e24-86d113d70a85", cont: oldtimer },
            { id: "72c42738-4dde-11eb-a8f4-b5d4fczuwe23", cont: sonderbau },
            { id: "f50c8cb2-9e24-419d-a70e-714d7067f0ff", cont: design }
        ]
    },
    mutations: {
        setLogo(state, ele) { state.logo = ele; },
        setEl(state, ele) { state.el = ele; },
        setLang(state, l) { state.lang = l; },
        setAktiveSl(state, i) { state.aktiveSl = i; },
        setAutostart(state, i) { state.autoplay = i; },
        setSlCont(state) {
            Object.keys(mwb.sliderImg).forEach(key => {
                let x = 1;
                Array.from(mwb.sliderImg[key]).forEach((slide) => {
                    slide.content = `<img id='slider${x}' src='${state.rootUrl}${slide.image}'>`;
                    ++x;
                });
            });
        },
    },
    getters: {
        getLogo: (state) => () => { return state.logo },
        getEl: (state) => () => { return state.el },
        getAktiveSl: (state) => () => { return state.aktiveSl },
        getLang: (state) => () => { return state.lang },
        getNavTopLevel: (state) => () => {
            if (state.lstNav.length <= 0) { Array.from(mwb.pages).forEach((page) => { state.lstNav.push(page.nav) }) }
            return state.lstNav.filter(function(nav) { return nav.toplevel === true });
        },
        getNav: (state) => () => {
            if (state.lstNav.length <= 0) { Array.from(mwb.pages).forEach((page) => { state.lstNav.push(page.nav) }) }
            return state.lstNav.filter(function(nav) { return nav.toplevel === false });
        },
        getSliderImg: (state) => (size) => {
            return size === "xs" ? state.lang === "de" ? mwb.sliderImg.slidesXS : mwb.sliderImg.slidesXSen : state.lang === "de" ? mwb.sliderImg.slidesXL : mwb.sliderImg.slidesXLen;
        },
        getPage: (state) => (id) => {
            let page = mwb.pages.filter(function(obj) { return obj.key === id })[0];
            if (page.content.length <= 0) {
                let cont = state.lstCont.filter(function(cont) { return cont.id === id })[0];
                page.content = cont.cont;
            }
            return page;
        }
    },
    actions: {},
    modules: {}
})