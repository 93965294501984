<template>
  <div class="grid w-full min-h-28 place-items-start sm:place-items-center md:place-items-center lg:place-items-center xl:place-items-center 2xl:place-items-center footer" 
    style="background-color:#232323">
    <div class="grid grid-cols-footer-smal sm:grid-cols-footer-2sp md:grid-cols-footer-2sp lg:grid-cols-footer xl:grid-cols-footer 2xl:grid-cols-footer">
      <div class="py-1 mr-2 xsm:mx-5 sm:mx-5">
        <div>phone: +49(0) 421-63992499</div>
        <div>mobil: +49(0) 174-7222444</div>
      </div>
      <div class="py-1 mr-1 xsm:mx-5 sm:mx-5">
        <div>mail@meisterwerk-bremen.de</div>
        <div>Inh. Michael Müller</div>
      </div>
      <div class="py-1 mr-2 xsm:mx-5 sm:mx-5">
        <div>Schönebecker Str. 39</div>
        <div>28759 Bremen</div>
      </div>
      <div class="grid py-1 xsm:mx-5 sm:mx-5 grid-cols-footer-2sp">
        <div>
          <div class="mb-1" v-for="(nav) in getNav()" :key="nav.key" >
            <router-link  :to="nav.link">{{getLang()==="de"?nav.titel.de:nav.titel.en}}</router-link>
          </div>          
        </div>        
        <div class="mx-10">
          <a title="Facebook" class="social" href="https://de-de.facebook.com/MeisterwerkBremen/" target="_blank" ><i class="icon-facebook-f"></i></a>        
          <a title="Instagram" class="social" href="https://www.instagram.com/meisterwerkbremen/" target="_blank" ><i class="icon-instagram"></i></a>        
        </div>
      </div>
    </div>      
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name:"Footer-src",
  computed: { ...mapGetters(['getLang','getNav']) },
}
</script>
